<template>
	<div class="page">
		<div v-if="$i18n.locale == 'zh'" class="games">
      <div
        v-for="(item, index) in $config.productZH"
        :key="index"
        class="game">
        <img class="game_icon" :src="item.icon">
        <div class="game_inf">
          <div class="game_name">{{item.name}}</div>
          <div class="game_text" v-html="item.text"></div>
          <div class="game_button_box">
            <div @click="goPath(1, item.down)" class="game_button game_button_1">游戏下载</div>
            <div @click="goPath(2, item.web)" class="game_button">进入官网</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="pleaseWait">Please Wait</div>
    </div>
	</div>
</template>
<script>

export default {
  data(){
    return {
      list: []
    }
  },
  methods: {
    goPath (type, url) { // 
      if (!url) {
        this.$alert('维护中', {
          confirmButtonText: '确定',
          callback: action => {}
        })
        return
      }
      window.open(url)
    }
  }
}
</script>
<style scoped>
.page{
  padding-top: 20px;
}
.game{
  display: flex;
  padding: 20px 0;
  box-sizing: border-box;
}
.game_icon{
  min-width: 240px;
  width: 240px;
  height: 240px;
  margin-right: 50px;
}
.game_inf{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.game_name{
  font-size: 22px;
  margin-bottom: 15px;
}
.game_text{
  flex: 1;
  font-size: 15px;
  line-height: 22px;
  color: #666;
}
.game_button_box{
  display: flex;
  margin-top: 20px;
}
.game_button{
  width: 150px;
  line-height: 50px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  background-color: #14a6a3;
  cursor: pointer;
  border-radius: 10px;
}
.game_button_1{
  margin-right: 50px;
  background-color: #f26522;
}
.pleaseWait{
  margin: 10px auto;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #666;
}
@media screen and (max-width: 700px) {
  .game{
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .game_icon{
    margin: 0;
  }
  .game_name{
    margin: 20px 0;
    text-align: center;
  }
  .game_button_box{
    justify-content: space-evenly;
  }
  .pleaseWait{
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .game_icon{
    width: 80vw;
    height: 80vw;
  }
  .game_button{
    width: 120px;
    line-height: 40px;
    font-size: 20px;
  }
  .game_button_1{
    margin-right: 20px;
  }
}
</style>
